import { combineReducers } from "redux";

import userInfoReducer from "./user";
import teamsListReducer from "./teamsLists";
import detailsTabReducer from "./detailTab";
import detailsFormValidationsReducer from "./detailsFormValidations";
import assignedPoiReducer from "./assignedPoi";
import assignedDriverReducer from "./assignedDrivers";
import configsTabReducer from "./configs";

const rootReducers = combineReducers({
  userInfo: userInfoReducer,
  teamsLists: teamsListReducer,
  detailTab: detailsTabReducer,
  detailsFormValidations: detailsFormValidationsReducer,
  assignedPoi: assignedPoiReducer,
  assignedDiver: assignedDriverReducer,
  configsTab: configsTabReducer,
});

export default rootReducers;
