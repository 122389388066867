import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
  query AccountListQuery($userId: Int!) {
    listAccounts(user_id: $userId) {
      id
      name
    }
  }
`;

export const GET_USER = gql`
  query GetUserQuery($userId: ID!) {
    getUserByID(id: $userId) {
      id
      lastName
      firstName
      clients
      clientId
      AccConfig
      roleType
      permissions
      timezone
    }
  }
`;

export const GET_CCOD_PARTNERS = gql`
  query CCODPartnersQuery {
    getCCODPartners {
      id
      name
    }
  }
`;
