import React, { useEffect, useRef, useState } from "react";
import TabTable from "../../components/TabTable/TabTable";
import { useDispatch, useSelector } from "react-redux";
import CommonService from "../../services/CommonService";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { poiAssign } from "../../reducers/assignedPoi";
import "./AssignedPois.css";
import { PoisService } from "../../services/PoisService";

export default function AssignedPois() {
  const commonService = new CommonService();
  const poisService = new PoisService();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userInfo.value);
  const teamsListSelector = useSelector((state) => state.teamsLists.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);

  const tableColumnsForEdit = [
    {
      id: "name",
      label: "Assigned POIs",
      minWidth: 150,
      searchBoxWidth: "100%",
    },
    {
      id: "team_type_text",
      label: "Assign Type",
      minWidth: 150,
      searchBoxWidth: "100%",
    },
  ];

  const tableColumnsForAdd = [
    {
      id: "name",
      label: "Assigned POIs",
      minWidth: 100,
      searchBoxWidth: "50%",
    },
  ];

  const [poisList, setPoisList] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState("false");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tempAssignedPoi, setTempAssignedPoi] = React.useState("");
  const [timerID, setTimerID] = useState(null);
  const [selectedPoiCount, setSelectedPoiCount] = useState(0);

  const selectedFilters = useRef({});
  const currentPage = useRef(0);
  const copyPoisList = useRef([]);

  const getPoisList = async () => {
    setIsLoading("true");
    setPoisList([]);

    const tempGetPois = poisService.getPoisList(
      user.id,
      currentPage.current,
      selectedFilters.current.name ? selectedFilters.current.name : ""
    );

    setTotalItemCount((await tempGetPois).total);

    copyPoisList.current = (await tempGetPois).poisList;
    setPoisList(copyPoisList.current);
    dispatch(poiAssign(copyPoisList.current));
    setIsLoading("false");
  };

  const getAssignedPoisList = async () => {
    setIsLoading("true");
    setPoisList([]);

    const tempGetAssignedPois = poisService.getAssignedPoisList(
      user.id,
      currentPage.current,
      selectedFilters.current.name ? selectedFilters.current.name : "",
      selectedFilters.current.team_type_text ? selectedFilters.current.team_type_text : "",
      detailsTabSelector.team_id
    );

    setTotalItemCount((await tempGetAssignedPois).total);

    copyPoisList.current = (await tempGetAssignedPois).poisList;
    setPoisList(copyPoisList.current);
    dispatch(poiAssign(copyPoisList.current));
    setIsLoading("false");
  };

  const onChangeOfCheckbox = (ev, checkedList) => {
    if (ev.target.checked) {
      setOpenDialog(true);
      setTempAssignedPoi(checkedList);
    } else {
      const tempRow = commonService.getDeepCopy(checkedList);
      tempRow.team_type = null;
      tempRow.team_type_text = "";
      setTempAssignedPoi(tempRow);
      const newTempArr = commonService.getDeepCopy(copyPoisList.current);
      newTempArr.forEach((row) => {
        if (row.id === checkedList.id) {
          row.team_type = null;
          row.team_type_text = "";
          row["checked"] = false;
        }
      });
      setPoisList([...newTempArr]);
      copyPoisList.current = newTempArr;
      dispatch(poiAssign(copyPoisList.current));
    }
  };

  const handleChangePage = (newPage) => {
    currentPage.current = newPage;
    if (teamsListSelector.viewType == "edit") {
      getAssignedPoisList();
    } else {
      getPoisList();
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onChangeTeamType = (ev) => {
    const tempData = commonService.getDeepCopy(tempAssignedPoi);
    tempData.team_type = ev;
    setTempAssignedPoi(tempData);
  };

  const applyTeamType = () => {
    const newTempArr = commonService.getDeepCopy(copyPoisList.current);
    newTempArr.forEach((row) => {
      if (row.id === tempAssignedPoi.id) {
        row.team_type = tempAssignedPoi.team_type;
        row["team_type_text"] = poisService.getTeamTypeText(
          tempAssignedPoi.team_type
        );
        row["checked"] = true;
      }
    });
    setPoisList([...newTempArr]);
    copyPoisList.current = newTempArr;

    const tempSelectedCount = copyPoisList.current.filter(
      (t) => t["checked"] == true
    ).length;
    if (tempSelectedCount !== 0) {
      setSelectedPoiCount(tempSelectedCount);
    } else {
      setSelectedPoiCount(0);
    }

    setOpenDialog(false);
    dispatch(poiAssign(copyPoisList.current));
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;
    setSelectedPoiCount(0);

    clearTimeout(timerID);
    const id = setTimeout(() => {
      if (teamsListSelector.viewType == "edit") {
        getAssignedPoisList();
      } else {
        getPoisList();
      }
    }, 1000);
    setTimerID(id);
  };

  useEffect(() => {
    if (teamsListSelector.viewType == "edit") {
      getAssignedPoisList();
    } else {
      getPoisList();
    }
  }, []);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{ width: "100%" }}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="align-center"
      >
        <DialogTitle id="alert-dialog-title">Assign Team</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-content"
          >
            <p>Delivery Area: {tempAssignedPoi.name}</p>
            <p>Select a Team Type:</p>
            <FormControl sx={{ minWidth: 80 }} variant="standard">
              <Select
                id="assign-team-type"
                label="Team Type"
                autoWidth
                value={tempAssignedPoi.team_type || ""}
                onChange={(e) => onChangeTeamType(e.target.value)}
              >
                <MenuItem value={"DOD"}>DOD/Force on-demand</MenuItem>
                <MenuItem value={"DEDICATED"}>Dedicated</MenuItem>
                <MenuItem value={"PREASSIGN"}>Pre-assign</MenuItem>
                <MenuItem value={"POOLING"}>On-demand/Pooling</MenuItem>
                <MenuItem value={"SACN"}>Scan to assign</MenuItem>
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={applyTeamType} autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <div
        style={{
          position: "absolute",
          margin: "auto",
          left: "3%",
          height: "70%",
          width: "94%",
          top: "25%",
          background: "rgb(255, 255, 255)",
          zIndex: "1",
          display: isLoading === "true" ? "block" : "none",
        }}
      >
        <CircularProgress
          style={{
            position: "absolute",
            margin: "auto",
            top: "40%",
            left: "50%",
          }}
        />
      </div>

      <div style={{ display: isLoading === "true" ? "none" : "block" }}>
        <TabTable
          tableColumns={
            teamsListSelector.viewType == "edit"
              ? tableColumnsForEdit
              : tableColumnsForAdd
          }
          tableData={poisList}
          search={search}
          isSelectAllRequired={false}
          selectedFilters={selectedFilters.current}
          onChangeOfCheckbox={onChangeOfCheckbox}
          currentPage={currentPage.current}
          onPageChange={handleChangePage}
          totalItemCount={totalItemCount}
          selectedCount={selectedPoiCount}
        ></TabTable>
      </div>
    </>
  );
}
