import React, { useEffect, useRef, useState } from "react";
import TabTable from "../../components/TabTable/TabTable";
import { useDispatch, useSelector } from "react-redux";
import CommonService from "../../services/CommonService";
import { useApolloClient } from "@apollo/client";
import { GET_DRIVERS } from "../../graphql/queries/DriverQuery";
import { assignDriver } from "../../reducers/assignedDrivers";
import { CircularProgress } from "@mui/material";
import { DriversService } from "../../services/DriversService";

export default function AssignedDrivers() {
  const commonService = new CommonService();
  const driversService = new DriversService();

  const client = useApolloClient();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userInfo.value);
  const teamsListSelector = useSelector((state) => state.teamsLists.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);

  const tableColumns = [
    {
      id: "name",
      label: "Assigned Drivers",
      minWidth: 100,
      searchBoxWidth: "50%",
    },
  ];

  const [isLoading, setIsLoading] = useState("false");
  const [driversList, setDriversList] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [selectedDriverCount, setSelectedDriverCount] = useState(0);
  const [timerID, setTimerID] = useState(null);

  const currentPage = useRef(0);
  const selectedFilters = useRef({});
  const copyDriversList = useRef([]);

  const getDriversList = async () => {
    setIsLoading("true");
    setDriversList([]);
    const tempGetDrivers = driversService.getDriversList(
      user.id,
      currentPage.current,
      selectedFilters.current.name ? selectedFilters.current.name : ""
    );

    setTotalItemCount((await tempGetDrivers).total);

    copyDriversList.current = (await tempGetDrivers).driversList;
    setDriversList(copyDriversList.current);
    dispatch(assignDriver(copyDriversList.current));
    setIsLoading("false");
  };

  const getAssignedDriversList = async () => {
    setIsLoading("true");
    setDriversList([]);
    const tempGetAssignedDrivers = driversService.getAssignedDriversList(
      user.id,
      currentPage.current,
      selectedFilters.current.name ? selectedFilters.current.name : "",
      detailsTabSelector.team_id
    );

    setTotalItemCount((await tempGetAssignedDrivers).total);

    copyDriversList.current = (await tempGetAssignedDrivers).driversList;
    setDriversList(copyDriversList.current);
    dispatch(assignDriver(copyDriversList.current));
    setIsLoading("false");
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;
    setSelectedDriverCount(0);

    clearTimeout(timerID);
    const id = setTimeout(() => {
      if (teamsListSelector.viewType == "edit") {
        getAssignedDriversList();
      } else {
        getDriversList();
      }
    }, 1000);
    setTimerID(id);
  };

  const onChangeOfCheckbox = (ev, checkedList) => {
    const newTempArr = commonService.getDeepCopy(copyDriversList.current);
    newTempArr.forEach((row) => {
      if (row.id === checkedList.id) {
        row["checked"] = ev.target.checked;
      }
    });
    setDriversList([...newTempArr]);
    copyDriversList.current = newTempArr;

    const tempSelectedCount = copyDriversList.current.filter(
      (t) => t["checked"] == true
    ).length;
    if (tempSelectedCount !== 0) {
      setSelectedDriverCount(tempSelectedCount);
    } else {
      setSelectedDriverCount(0);
    }

    dispatch(assignDriver(copyDriversList.current));
  };

  const handleChangePage = (newPage) => {
    currentPage.current = newPage;
    if (teamsListSelector.viewType == "edit") {
      getAssignedDriversList();
    } else {
      getDriversList();
    }
  };

  useEffect(() => {
    if (teamsListSelector.viewType == "edit") {
      getAssignedDriversList();
    } else {
      getDriversList();
    }
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          margin: "auto",
          left: "3%",
          height: "70%",
          width: "94%",
          top: "25%",
          background: "rgb(255, 255, 255)",
          zIndex: "1",
          display: isLoading === "true" ? "block" : "none",
        }}
      >
        <CircularProgress
          style={{
            position: "absolute",
            margin: "auto",
            top: "40%",
            left: "50%",
          }}
        />
      </div>
      <div style={{ display: isLoading === "true" ? "none" : "block" }}>
        <TabTable
          tableColumns={tableColumns}
          tableData={driversList}
          search={search}
          isSelectAllRequired={false}
          selectedFilters={selectedFilters.current}
          onChangeOfCheckbox={onChangeOfCheckbox}
          currentPage={currentPage.current}
          onPageChange={handleChangePage}
          totalItemCount={totalItemCount}
          selectedCount={selectedDriverCount}
        ></TabTable>
      </div>
    </>
  );
}
