import { useApolloClient } from "@apollo/client";
import { Alert, Snackbar } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionToolBar from "../../components/ActionToolBar/ActionToolBar";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import ConfirmationDialog from "../../components/ConfirmDialog/ConfirmationDialog";
import ListTable from "../../components/ListTable/ListTable";
import TabModal from "../../components/TabModal/TabModal";
import {
  ACTIVATE_TEAM,
  DEACTIVATE_TEAM,
  DELETE_TEAM,
} from "../../graphql/mutations/TeamsListMutation";
import { GET_ACCOUNTS, GET_USER } from "../../graphql/queries/CommonQuery";
import {
  GET_FILTERS,
  GET_TEAMS_LIST,
  GET_TEAM_DETAILS,
} from "../../graphql/queries/TeamsListQuery";
import { formDetailsTab } from "../../reducers/detailTab";
import { reloadTeamList, teamsLists } from "../../reducers/teamsLists";
import { userInfo } from "../../reducers/user";
import CommonService from "../../services/CommonService";
import TeamsService from "../../services/TeamsService";
import "./TeamsList.css";
export default function TeamsList() {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const commonService = new CommonService();
  const teamsService = new TeamsService();

  const teamsListSelector = useSelector((state) => state.teamsLists.value);
  const userInfoSelector = useSelector((state) => state.userInfo.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const reloadTeamListSelector = useSelector(
    (state) => state.teamsLists.reloadState
  );

  const [isLoading, setIsLoading] = useState("false");
  const [alertProps, setAlertProps] = useState({
    title: "",
    description: "",
    display: false,
  });
  const [teamList, setTeamList] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [accountsList, setAccountsList] = useState([]);
  const [checkedLists, setCheckedLists] = useState([]);
  const [timerID, setTimerID] = useState(null);
  const [dropdownFilters, setDropdownFilters] = useState({
    country: [],
    city: [],
    pois: [],
  });
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [confirmProps, setConfirmProps] = useState({
    title: "",
    id: "]",
    description: "",
    display: false,
  });
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    type: "",
    message: "",
  });

  const copyTeamsList = useRef([]);
  const selectedAccount = useRef({ id: "", name: "" });
  const tabModalChild = useRef(null);
  const selectedTeams = useRef([]);
  const selectedFilters = useRef({});
  const currentPage = useRef(0);
  const hideOptions = React.useRef({
    showActivate: false,
    showDeactivate: false,
  });

  const columns = teamsService.columns;

  const onAddClick = () => {
    tabModalChild.current();
    const teamsListFields = commonService.getDeepCopy(teamsListSelector);
    teamsListFields["viewType"] = "add";
    dispatch(teamsLists(teamsListFields));
  };

  const getUserData = async () => {
    setIsLoading("true");
    let userId = Cookies.get("user_id");
    if (userId) {
      const fetchUserData = await client.query({
        query: GET_USER,
        variables: { userId: userId },
        fetchPolicy: "network-only",
      });
      let userData = commonService.getDeepCopy(fetchUserData.data.getUserByID);
      userData["permissions"] = JSON.parse(
        fetchUserData.data.getUserByID.permissions
      );
      dispatch(userInfo(userData));
      getAccountsList(userId);
    } else {
      setAlertProps({
        title: "Session Timeout",
        description: "Your session timed out! Please login again",
        display: true,
      });
    }
  };

  const getAccountsList = async (inUserId) => {
    const tempGetAccounts = await client.query({
      query: GET_ACCOUNTS,
      variables: { userId: inUserId },
      fetchPolicy: "network-only",
    });
    const teamsListFields = commonService.getDeepCopy(teamsListSelector);
    teamsListFields["accountsList"] = tempGetAccounts.data.listAccounts;
    dispatch(teamsLists(teamsListFields));
    if (tempGetAccounts.data.listAccounts.length === 1) {
      onAccountSelection("", tempGetAccounts.data.listAccounts[0]);
    }
    setAccountsList(tempGetAccounts.data.listAccounts);
    setIsLoading("false");
  };

  const onAccountSelection = (ev, value) => {
    selectedTeams.current = [];
    selectedFilters.current = {};
    setCheckedLists([]);
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    const teamListFields = commonService.getDeepCopy(teamsListSelector);
    if (value) {
      selectedAccount.current = value;
      currentPage.current = 0;
      getTeamsList().then(() => getFilters());
    } else {
      selectedAccount.current = {};
      setFilterApplied(false);
      setTotalItemCount(0);
    }
    tempFields["client_id"] = value;
    teamListFields["selectedAccount"] = value;
    dispatch(formDetailsTab(tempFields));
    dispatch(teamsLists(teamListFields));
  };

  async function getTeamsList() {
    setIsLoading("true");
    setTeamList([]);
    copyTeamsList.current = [];
    const tempGetTeamsList = await client.query({
      query: GET_TEAMS_LIST,
      variables: teamsService.prepareVariablesToGetTeams(
        selectedAccount,
        currentPage,
        selectedFilters
      ),
      fetchPolicy: "network-only",
    });
    setTotalItemCount(tempGetTeamsList.data.listTeamsPaginated.total);
    copyTeamsList.current = teamsService.prepareTeamsList(
      tempGetTeamsList.data.listTeamsPaginated.data,
      checkedLists
    );
    const checkFilterLength = Object.keys(selectedFilters.current).length;
    pageChanged(checkFilterLength ? true : false);
    setTeamList(copyTeamsList.current);
    setIsLoading("false");
  }

  const onAlertClose = (ev) => {
    setAlertProps({ title: "", description: "", display: false });
    if (ev === "Session Timeout") {
      window.location.href = process.env.REACT_APP_BASE_API_URL;
    } else {
      getFilters();
      getTeamsList();
    }
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;

    clearTimeout(timerID);
    const id = setTimeout(() => getTeamsList(), 1000);
    setTimerID(id);
  };

  const onFiltersChange = (inFilters, inColumn) => {
    const tempFilterArr = [];
    currentPage.current = 0;
    if (inFilters) {
      inFilters.forEach((src) => {
        tempFilterArr.push(src);
      });
      if (tempFilterArr.length) {
        selectedFilters.current[inColumn] = tempFilterArr;
      } else {
        delete selectedFilters.current[inColumn];
      }
    }

    getTeamsList();
  };

  const getFilters = async () => {
    setIsLoading("true");
    copyTeamsList.current = [];
    const tempGetFilters = await client.query({
      query: GET_FILTERS,
      variables: { clientId: selectedAccount.current.id },
      fetchPolicy: "network-only",
    });

    const dropFilters = {
      country: tempGetFilters.data.getTeamsCountriesFilter,
      city: tempGetFilters.data.getTeamsCitiesFilter,
      pois: tempGetFilters.data.getTeamsPoisFilter,
    };
    setDropdownFilters(dropFilters);
    setIsLoading("false");
  };

  const onChangeOfCheckbox = (inCheckedList) => {
    setCheckedLists(inCheckedList);
  };

  const checkActivateDeactivateHideState = () => {
    if (checkedLists && checkedLists.length) {
      const checkForActiveTeam = checkedLists.filter(
        (elem) => elem.is_active == 1
      );
      const checkForInactiveTeam = checkedLists.filter(
        (elem) => elem.is_active == 0
      );
      if (checkForActiveTeam && checkForActiveTeam.length) {
        hideOptions.current.showDeactivate = true;
      } else {
        hideOptions.current.showDeactivate = false;
      }
      if (checkForInactiveTeam && checkForInactiveTeam.length) {
        hideOptions.current.showActivate = true;
      } else {
        hideOptions.current.showActivate = false;
      }
    } else {
      hideOptions.current = { showActivate: false, showDeactivate: false };
    }
  };

  const handleChangePage = (newPage) => {
    setCheckedLists([]);
    currentPage.current = newPage;
    getTeamsList();
  };

  const onSelectSettingsOption = (inOption) => {
    if (inOption !== "close") {
      setConfirmProps(
        teamsService.selectedSettingsOptionsForTeamList(inOption)
      );
    }
  };

  const onConfirmClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
    const tempIds = checkedLists.map((item) => Number(item.id));
    switch (ev.id) {
      case "activate":
        activateTeam(tempIds);
        break;
      case "deactivate":
        deactivateTeam(tempIds);
        break;
      case "delete":
        deleteTeam(tempIds);
        setCheckedLists([]);
        break;
      default:
        return;
    }
  };

  const pageChanged = (isFilterApplied) => {
    if (isFilterApplied) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  };

  const activateTeam = async (inIds) => {
    setIsLoading("true");
    await client.mutate({
      mutation: ACTIVATE_TEAM,
      variables: { ids: inIds },
    });
    setAlertProps({
      title: "Activate",
      description: "The Team(s) have been activated successfully.",
      display: true,
    });
  };

  const deactivateTeam = async (inIds) => {
    setIsLoading("true");
    await client.mutate({
      mutation: DEACTIVATE_TEAM,
      variables: { ids: inIds },
    });
    setAlertProps({
      title: "Deactivate",
      description: "The Team(s) have been deactivated successfully.",
      display: true,
    });
  };

  const deleteTeam = async (inIds) => {
    setIsLoading("true");
    await client.mutate({ mutation: DELETE_TEAM, variables: { ids: inIds } });
    setAlertProps({
      title: "Delete",
      description: "The Team(s) have been deleted successfully.",
      display: true,
    });
  };

  const onCancelClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
  };

  const onEditClick = (inId) => {
    if (userInfoSelector.permissions.teams_viewTeams) {
      const teamListFields = commonService.getDeepCopy(teamsListSelector);
      teamListFields["viewType"] = "edit";
      teamListFields["selectedTeam"] = inId;
      dispatch(teamsLists(teamListFields));
      getTeamDetails(inId);
    } else {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "You don't have the permission!",
        type: "error",
      });
    }
  };

  const getTeamDetails = async (inTeamId) => {
    setIsLoading("true");
    const tempGetTeam = await client.query({
      query: GET_TEAM_DETAILS,
      variables: { teamId: inTeamId },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
    if (tempGetTeam.errors) {
      const errMessage = tempGetTeam.errors.map((er) => er.message);
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        type: "error",
        message: errMessage,
      });
      setIsLoading("false");
    } else {
      teamsService.initializeGetTeam(tempGetTeam);
      setIsLoading("false");
      tabModalChild.current();
    }
  };

  const onActivateClick = () => {
    setConfirmProps({
      title: "Activate",
      id: "activate",
      description: "All the changes will be saved. Do you want to proceed?",
      display: true,
    });
  };
  const onDeactivateClick = () => {
    setConfirmProps({
      title: "Deactivate",
      id: "deactivate",
      description: "All the changes will be saved. Do you want to proceed?",
      display: true,
    });
  };
  const onDeleteClick = () => {
    setConfirmProps({
      title: "Delete",
      id: "delete",
      description: "All the changes will be saved. Do you want to proceed?",
      display: true,
    });
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      type: "",
      message: "",
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    checkActivateDeactivateHideState();
  }, [checkedLists]);

  useEffect(() => {
    if (reloadTeamListSelector.isReload) {
      getFilters();
      getTeamsList();
      dispatch(reloadTeamList(false));
    }
  }, [reloadTeamListSelector]);

  useEffect(() => {
    if (
      userInfoSelector?.permissions &&
      !userInfoSelector?.permissions?.teams_viewTeams
    ) {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "You don't have the permission to access the page!",
        type: "error",
      });
    } else {
      setSnackBarState({
        open: false,
        vertical: "top",
        horizontal: "center",
        message: "",
      });
    }
  }, [userInfoSelector]);

  return (
    <div className="p-4">
      <ConfirmationDialog
        confirmProps={confirmProps}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      ></ConfirmationDialog>
      <AlertDialog alertProps={alertProps} onAlertClose={onAlertClose} />
      <Snackbar
        anchorOrigin={{
          vertical: snackBarState.vertical,
          horizontal: snackBarState.horizontal,
        }}
        autoHideDuration={6000}
        open={snackBarState.open}
        key={snackBarState.vertical + snackBarState.horizontal}
        onClose={closeSnackBar}
      >
        <Alert
          onClose={closeSnackBar}
          severity={snackBarState.type}
          sx={{ width: "100%" }}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <ActionToolBar
        accountsList={accountsList}
        selectedAccount={selectedAccount.current}
        onAddClick={onAddClick}
        onAccountSelection={onAccountSelection}
        hideOptions={hideOptions.current}
        isDisableEdit={checkedLists.length === 1 ? false : true}
        isDisabledSettings={checkedLists.length ? false : true}
        pageType={"dashboard"}
        onSelectSettingsOption={onSelectSettingsOption}
        onEditClick={() => onEditClick(checkedLists[0].id)}
        user={userInfoSelector}
        onActivateClick={onActivateClick}
        onDeactivateClick={onDeactivateClick}
        onDeleteClick={onDeleteClick}
      ></ActionToolBar>
      <>
        <>
          {selectedAccount.current.name ? (
            <ListTable
              columns={columns}
              dataList={teamList}
              search={search}
              selectedFilters={selectedFilters.current}
              onFiltersChange={onFiltersChange}
              onChangeOfCheckbox={onChangeOfCheckbox}
              currentPage={currentPage.current}
              onPageChange={handleChangePage}
              totalItemCount={totalItemCount}
              dropdownFilters={dropdownFilters}
              onEditClick={onEditClick}
              tabTitle={
                teamsListSelector.viewType == "edit" ? "Edit Team" : "Add Team"
              }
              onSelectSettingsOption={onSelectSettingsOption}
              isLoading={isLoading}
            ></ListTable>
          ) : (
            <div
              className="select-account"
              style={{
                display:
                  (!selectedAccount.current.name || !totalItemCount) &&
                  !isFilterApplied
                    ? "block"
                    : "none",
              }}
            >
              <p>
                {selectedAccount.current.name && !totalItemCount
                  ? "No data in the selected Account"
                  : "Select an Account to load data"}
              </p>
            </div>
          )}
        </>
      </>
      <TabModal
        tabModalChild={tabModalChild}
        onSelectSettingsOption={onSelectSettingsOption}
        tabsList={teamsService.tabsList}
        user={userInfoSelector}
        viewType={teamsListSelector.viewType}
      ></TabModal>
    </div>
  );
}
