import { gql } from "@apollo/client";

export const GET_TEAMS_LIST = gql`
  query TeamsListQuery(
    $client_id: String
    $page_no: Int!
    $name: String
    $integrationId: String
    $country: String
    $city: String
    $pois: String
  ) {
    listTeamsPaginated(
      filters: {
        client_id: $client_id
        page_no: $page_no
        name: $name
        integration_id: $integrationId
        countries: $country
        cities: $city
        pois: $pois
      }
    ) {
      total
      data {
        id
        name
        country_id
        client_id
        city_id
        pois {
          name
          id
        }
        integration_id
        country {
          id
          name
        }
        city {
          id
          name
        }
        is_active
      }
    }
  }
`;

export const GET_FILTERS = gql`
  query FiltersQuery($clientId: Int!) {
    getTeamsCitiesFilter(client_id: $clientId) {
      id
      name
    }
    getTeamsCountriesFilter(client_id: $clientId) {
      id
      name
    }
    getTeamsPoisFilter(client_id: $clientId) {
      id
      name
    }
  }
`;

export const GET_COUNTRIES = gql`
  query CountryQuery($clientId: Int!) {
    listCountries(client_id: $clientId) {
      id
      name
    }
  }
`;

export const GET_CITIES = gql`
  query listCities($country_id: Int!) {
    listCities(country_id: $country_id) {
      id
      name
    }
  }
`;

export const GET_DETAILS_TAB_EDIT_DROPDOWNS_WITH_COUNTRY = gql`
  query EditDropdownQueryWithCountry($country_id: Int!, $clientId: Int!) {
    listCountries(client_id: $clientId) {
      id
      name
    }
    listCities(country_id: $country_id) {
      id
      name
    }
  }
`;

export const GET_TEAM_DETAILS = gql`
  query GetTeamDetailQuery($teamId: ID!) {
    getTeamByID(id: $teamId) {
      id
      name
      is_active
      client_id
      country {
        id
        name
      }
      city {
        id
        name
      }
      short_name
      edge_points
      settings {
        configs {
          CCOD_parnter_select
          cashier_start_date
          is_cashier_team_on
          is_double_auth
          is_double_petty_cash
          is_shift_message
          payment_method_through_app
        }
        alerts {
          alert_type
          alert_value
          id
          is_alert_on
          team_id
        }
      }
    }
  }
`;
