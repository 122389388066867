import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SettingsMenu from "../SettingsMenu/SettingsMenu";

export default function ActionToolBar(props) {
  const [accountsList, setAccountsList] = useState([]);
  const permissions = props.user.permissions;

  const onAccountSelection = (ev, value) => {
    props.onAccountSelection(ev, value);
  };

  useEffect(() => {
    setAccountsList(props.accountsList);
  }, [props]);

  return (
    <div className="row pb-3">
      <div className="d-flex wrapper">
        <div className="col-sm-6 pb-3">
          <Autocomplete
            className="mt-3"
            disablePortal
            options={accountsList}
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            autoComplete
            onChange={onAccountSelection}
            size="small"
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Account" />}
            value={props.selectedAccount || ""}
          />
        </div>
        <div className="col-sm-6 button-div">
          {permissions?.teams_viewTeams && permissions?.teams_createTeam && (
            <Button
              variant="contained"
              size="small"
              onClick={() => props.onAddClick()}
            >
              Add
            </Button>
          )}
          {permissions?.teams_viewTeams && permissions?.teams_editTeam && (
            <Button
              variant="contained"
              size="small"
              onClick={props.onEditClick}
              disabled={props.isDisableEdit}
            >
              Edit
            </Button>
          )}
          {/*<Button variant="contained" size="small">*/}
          {/*  Export*/}
          {/*</Button>*/}
          <p>{props.isDisabledSettings}</p>
          <SettingsMenu
            pageType={props.pageType}
            hideOptions={props.hideOptions}
            isDisabledState={props.isDisabledSettings}
            onSelectSettingsOption={(ev) => props.onSelectSettingsOption(ev)}
            permissions={permissions}
            onActivateClick={props.onActivateClick}
            onDeactivateClick={props.onDeactivateClick}
            onDeleteClick={props.onDeleteClick}
          ></SettingsMenu>
        </div>
      </div>
    </div>
  );
}
