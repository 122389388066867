import { useApolloClient } from "@apollo/client";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
  DrawingManager,
  Polygon,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ACCOUNTS } from "../../graphql/queries/CommonQuery";
import {
  GET_CITIES,
  GET_COUNTRIES,
  GET_DETAILS_TAB_EDIT_DROPDOWNS_WITH_COUNTRY,
} from "../../graphql/queries/TeamsListQuery";
import CommonService from "../../services/CommonService";
import { dropdownOptions, formDetailsTab } from "../../reducers/detailTab";
import { teamsLists } from "../../reducers/teamsLists";
import { detailsFormErrorValidations } from "../../reducers/detailsFormValidations";

const googleMapAPIKey = "AIzaSyDT4BxUMab89jQzhUfnxl8XUZNORb_0tLg";

const mapLibraries = ["places", "drawing"];

const containerStyleForMap = {
  width: "100%",
  height: "300px",
};

export default function TeamsDetails() {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const commonService = new CommonService();

  const user = useSelector((state) => state.userInfo.value);
  const dropdownOptionsSelector = useSelector(
    (state) => state.detailTab.dropdownOptions
  );
  const teamsListSelector = useSelector((state) => state.teamsLists.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const errorValidationSelector = useSelector(
    (state) => state.detailsFormValidations.value
  );

  const [account, setAccount] = useState([]);
  const [isLoading, setIsLoading] = useState("false");
  const [mapRef, setMapRef] = useState(null);
  const [mapZoom, setMapZoom] = useState(12);
  const [polyPaths, setPolyPaths] = React.useState(null);
  const [polygonRef, setPolygonRef] = React.useState(null);

  const mapOptions = {
    fillColor: "lightblue",
    fillOpacity: 0.5,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: user.permissions.poi_editDeliveryBoundaryTab ? true : false,
    editable: user.permissions.poi_editDeliveryBoundaryTab ? true : false,
    geodesic: true,
    zIndex: 1,
  };

  const onMarkerClick = (ev) => {
    if (ev && ev.latLng) {
      const tempFields = commonService.getDeepCopy(detailsTabSelector);
      tempFields["latitude"] = ev.latLng.lat();
      tempFields["longitude"] = ev.latLng.lng();
      dispatch(formDetailsTab(tempFields));
    }
  };

  const onPlacesChanged = () => {
    const tempPlace = mapRef.getPlaces() ? mapRef.getPlaces()[0] : null;
    if (tempPlace && tempPlace.geometry && tempPlace.geometry.location) {
      const tempFields = commonService.getDeepCopy(detailsTabSelector);
      tempFields["latitude"] = tempPlace.geometry.location.lat();
      tempFields["longitude"] = tempPlace.geometry.location.lng();
      setMapZoom(18);
      dispatch(formDetailsTab(tempFields));
    }
  };

  const getAccountsList = async () => {
    if (!dropdownOptionsSelector || !dropdownOptionsSelector.accountsList) {
      const tempGetAccounts = await client.query({
        query: GET_ACCOUNTS,
        variables: { userId: user.id },
        fetchPolicy: "network-only",
      });
      setAccount(tempGetAccounts.data.listAccounts);
    }

    setIsLoading("false");

    if (
      teamsListSelector.selectedAccount &&
      teamsListSelector.selectedAccount.id &&
      teamsListSelector.viewType === "add"
    ) {
      await getCountries(teamsListSelector.selectedAccount.id);
    }
  };

  async function getCountries(inClientId) {
    setIsLoading("true");
    const tempGetFilterList = await client.query({
      query: GET_COUNTRIES,
      variables: { clientId: inClientId },
      fetchPolicy: "network-only",
    });
    const tempOptions = commonService.getDeepCopy(dropdownOptionsSelector);
    tempOptions["countryLists"] = tempGetFilterList.data.listCountries;
    await dispatch(dropdownOptions(tempOptions));
    setIsLoading("false");
  }

  async function getCities(inCountryId) {
    setIsLoading("true");
    const tempGetCityList = await client.query({
      query: GET_CITIES,
      variables: { country_id: inCountryId },
      fetchPolicy: "network-only",
    });
    const tempCityOptions = commonService.getDeepCopy(dropdownOptionsSelector);
    tempCityOptions["cityLists"] = tempGetCityList.data.listCities;
    await dispatch(dropdownOptions(tempCityOptions));
    setIsLoading("false");
  }

  const getDropdownsForEditView = async () => {
    let tempDropdowns;
    if (detailsTabSelector.country_id) {
      tempDropdowns = await client.query({
        query: GET_DETAILS_TAB_EDIT_DROPDOWNS_WITH_COUNTRY,
        variables: {
          clientId: detailsTabSelector.client_id.id,
          country_id: detailsTabSelector.country_id.id,
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });

      const tempOptions = commonService.getDeepCopy(dropdownOptionsSelector);
      tempOptions["countryLists"] = tempDropdowns.data.listCountries;
      tempOptions["cityLists"] = tempDropdowns.data.listCities;
      await dispatch(dropdownOptions(tempOptions));
    } else {
      getCountries(teamsListSelector.selectedAccount.id);
    }
  };

  const onDropdownSelection = (value, type) => {
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    const tempValidation = commonService.getDeepCopy(errorValidationSelector);
    if (type === "client_id") {
      if (value) {
        const dependentFields = ["country_id", "city_id"];
        dependentFields.forEach((field) => {
          tempFields[field] = "";
          tempValidation[field] = "Field is required";
        });
        getCountries(value.id);
      } else {
        tempFields[type] = value;
        const tempOptions = {
          countryLists: [],
          cityLists: [],
        };
        dispatch(dropdownOptions(tempOptions));
      }
      const poiListFields = commonService.getDeepCopy(teamsListSelector);
      poiListFields["selectedAccount"] = value;
      dispatch(teamsLists(poiListFields));
    } else if (type === "country_id") {
      if (value) {
        getCities(value.id);
        tempFields["city_id"] = "";
        tempValidation["city_id"] = "Field is required";
      } else {
        tempFields[type] = value;
        const tempOptions = commonService.getDeepCopy(dropdownOptionsSelector);
        tempOptions["cityLists"] = [];
        dispatch(dropdownOptions(tempOptions));
      }
    }
    tempFields[type] = value;
    tempValidation[type] = value ? "" : "Field is required";
    dispatch(formDetailsTab(tempFields));
    dispatch(detailsFormErrorValidations(tempValidation));
  };

  const handleChange = (value, inField) => {
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    tempFields[inField] = value;
    dispatch(formDetailsTab(tempFields));

    const tempValidation = commonService.getDeepCopy(errorValidationSelector);
    tempValidation[inField] = value ? "" : "Field is required";
    dispatch(detailsFormErrorValidations(tempValidation));
  };

  const onMapLoad = (ev) => {
    setMapRef(ev);
  };

  const onPolygonLoad = (inPolygon) => {
    setPolygonRef(inPolygon);
    const polygonBounds = inPolygon.getPath();
    const bounds = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      const point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }
    setPolyPaths(bounds);
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    tempFields["edge_points"] = bounds;
    dispatch(formDetailsTab(tempFields));
  };

  const onPolygonMouseUp = (ev) => {
    const polygonBounds = polygonRef.getPath();
    const bounds = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      const point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }
    setPolyPaths(bounds);
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    tempFields["edge_points"] = bounds;
    dispatch(formDetailsTab(tempFields));
  };

  const onPolygonComplete = (inPolygon) => {
    const polygonBounds = inPolygon.getPath();
    const bounds = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      const point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }
    setPolyPaths(bounds);
    inPolygon.setMap(null);
  };

  useEffect(() => {
    if (teamsListSelector.viewType == "edit") {
      if (dropdownOptionsSelector && dropdownOptionsSelector.accountsList) {
        setAccount(dropdownOptionsSelector.accountsList);
      }
      getDropdownsForEditView();
      if (detailsTabSelector.edge_points) {
        const newPoints = detailsTabSelector.edge_points.map((boundary) => ({
          lat: Number(boundary.lat),
          lng: Number(boundary.lng),
        }));
        setPolyPaths(newPoints);
      }
    } else {
      if (dropdownOptionsSelector && dropdownOptionsSelector.accountsList) {
        setAccount(dropdownOptionsSelector.accountsList);
      } else {
        setIsLoading("true");
        getAccountsList();
      }
    }
  }, []);

  return (
    <Container className="p-0">
      <CircularProgress
        style={{
          position: "absolute",
          margin: "auto",
          top: "50%",
          left: "50%",
          display: isLoading === "true" ? "block" : "none",
        }}
      />
      <div>
        <Grid className="pb-3">
          <Grid item xs={12}>
            <LoadScript
              googleMapsApiKey={googleMapAPIKey}
              libraries={mapLibraries}
            >
              <GoogleMap
                mapContainerStyle={containerStyleForMap}
                center={{
                  lat: Number(detailsTabSelector.latitude),
                  lng: Number(detailsTabSelector.longitude),
                }}
                zoom={mapZoom}
                onClick={onMarkerClick}
              >
                <StandaloneSearchBox
                  onLoad={(e) => onMapLoad(e)}
                  onPlacesChanged={(place) => onPlacesChanged(place)}
                >
                  <input
                    type="text"
                    style={{
                      width: "30%",
                      right: "4rem",
                      top: "10px",
                      height: "2.5rem",
                      padding: "5px",
                    }}
                    className="form-control position-absolute"
                    placeholder="Enter Location"
                  />
                </StandaloneSearchBox>
                <Marker
                  position={{
                    lat: Number(detailsTabSelector.latitude),
                    lng: Number(detailsTabSelector.longitude),
                  }}
                />
                {polyPaths ? (
                  <Polygon
                    onLoad={onPolygonLoad}
                    paths={polyPaths}
                    options={mapOptions}
                    onMouseUp={onPolygonMouseUp}
                  />
                ) : (
                  <DrawingManager
                    options={{
                      drawingControl: user.permissions
                        .poi_editDeliveryBoundaryTab
                        ? true
                        : false,
                      drawingControlOptions: {
                        drawingModes: ["polygon"],
                      },
                    }}
                    onPolygonComplete={onPolygonComplete}
                  />
                )}
              </GoogleMap>
            </LoadScript>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: "5px" }}>
            <Box className={!errorValidationSelector.name && "mb-4"}>
              <Typography component={"div"} className="mb-1">
                Team Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                error={errorValidationSelector.name ? true : false}
                size="small"
                required
                variant="outlined"
                sx={{
                  width: "100%",
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                value={detailsTabSelector.name || ""}
                onChange={(ev) => handleChange(ev.target.value, "name")}
                helperText={errorValidationSelector.name}
              />
            </Box>
            <Box className={!errorValidationSelector.country_id && "mb-4"}>
              <Typography component={"div"} className="mb-1">
                Team Country <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                disablePortal
                size="small"
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                options={dropdownOptionsSelector.countryLists || []}
                getOptionLabel={(option) => option.name || ""}
                autoComplete
                value={detailsTabSelector.country_id || ""}
                onChange={(ev, val) => onDropdownSelection(val, "country_id")}
                disabled={!dropdownOptionsSelector.countryLists}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    {...params}
                    error={errorValidationSelector.country_id ? true : false}
                    helperText={errorValidationSelector.country_id}
                  />
                )}
              />
            </Box>
            <Box className="mb-4">
              <Typography className="mb-1">Team Short Name</Typography>
              <TextField
                size="small"
                required
                variant="outlined"
                sx={{
                  width: "100%",
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                value={detailsTabSelector.short_name || ""}
                onChange={(ev) => handleChange(ev.target.value, "short_name")}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: "5px" }}>
            <Box className={!errorValidationSelector.client_id && "mb-4"}>
              <Typography component={"div"} className="mb-1">
                Team Account <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                disablePortal
                size="small"
                variant="outlined"
                options={account}
                getOptionLabel={(option) => option.name || ""}
                autoComplete
                value={detailsTabSelector.client_id || ""}
                onChange={(ev, val) => onDropdownSelection(val, "client_id")}
                disabled={!account}
                renderInput={(params) => (
                  <TextField
                    placeholder="None"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    {...params}
                    error={errorValidationSelector.client_id ? true : false}
                    helperText={errorValidationSelector.client_id}
                  />
                )}
              />
            </Box>
            <Box className={!errorValidationSelector.city_id && "mb-4"}>
              <Typography component={"div"} className="mb-1">
                Team City <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                disablePortal
                size="small"
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                options={dropdownOptionsSelector.cityLists || []}
                getOptionLabel={(option) => option.name || ""}
                autoComplete
                value={detailsTabSelector.city_id || ""}
                onChange={(ev, val) => onDropdownSelection(val, "city_id")}
                disabled={
                  !dropdownOptionsSelector.cityLists ||
                  !user.permissions.poi_editPoiDetails
                }
                renderInput={(params) => (
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    {...params}
                    error={errorValidationSelector.city_id ? true : false}
                    helperText={errorValidationSelector.city_id}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
