import { createSlice } from "@reduxjs/toolkit";

export const detailsTabSlice = createSlice({
  name: "formDetailsTab",
  initialState: {
    value: { latitude: 25.2048, longitude: 55.2708 },
    dropdownOptions: {},
  },
  reducers: {
    formDetailsTab: (state, action) => {
      state.value = action.payload;
    },
    dropdownOptions: (state, action) => {
      state.dropdownOptions = action.payload;
    },
  },
});

export const { formDetailsTab, dropdownOptions } = detailsTabSlice.actions;

export default detailsTabSlice.reducer;
