import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  CircularProgress,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./ListTable.css";

export default function ListTable(props) {
  const [selectAll, setSelectAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [dataList, setDataList] = useState(props.dataList);
  const [totalItemCount, setTotalItemCount] = useState(props.totalItemCount);
  const [selectedListCount, setSelectedListCount] = useState(0);
  const [rowsPerPage] = useState(20);

  const copyDataList = useRef(props.dataList);
  const selectedList = useRef([]);
  const selectedFilters = useRef(props.selectedFilters);

  const columns = props.columns;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onChangeSelectAll = (event) => {
    const newArr = copyDataList.current;
    newArr.forEach((row) => {
      row["checked"] = event.target.checked;
    });
    selectedList.current = event.target.checked
      ? newArr.map((el) => el.id)
      : [];
    setSelectAll(event.target.checked);
    setSelectedListCount(event.target.checked ? newArr.length : 0);
    setDataList([...newArr]);
    copyDataList.current = newArr;
    setIsIndeterminate(false);
    const checkedLists = copyDataList.current.filter((list) => list.checked);
    props.onChangeOfCheckbox(checkedLists);
  };

  const search = (searchString, field) => {
    props.search(searchString, field);
  };

  const onListSelect = (ev, inRow) => {
    if (ev.target.checked) {
      selectedList.current.push(inRow.id);
    } else {
      const index = selectedList.current.indexOf(inRow.id);
      const tempArr = JSON.parse(JSON.stringify(selectedList.current));
      tempArr.splice(index, 1);
      selectedList.current = tempArr;
    }
    const newTempArr = copyDataList.current;
    newTempArr.forEach((row) => {
      if (row.id === inRow.id) {
        row["checked"] = ev.target.checked;
      }
    });
    setDataList([...newTempArr]);
    copyDataList.current = newTempArr;
    checkIfIndeterminate();
    const checkedLists = copyDataList.current.filter((list) => list.checked);
    props.onChangeOfCheckbox(checkedLists);
  };

  const checkIfIndeterminate = () => {
    const tempSelectedCount = copyDataList.current.filter(
      (t) => t["checked"] === true
    ).length;
    const tempTotalCount = copyDataList.current.length;
    if (tempSelectedCount !== 0) {
      setSelectedListCount(tempSelectedCount);
      if (tempSelectedCount === tempTotalCount) {
        setSelectAll(true);
        setIsIndeterminate(false);
      } else {
        setIsIndeterminate(true);
      }
    } else {
      setIsIndeterminate(false);
      setSelectAll(false);
      setSelectedListCount(0);
    }
  };

  const getUiElement = (column, value, id) => {
    if (column.id === "name") {
      return (
        <Tooltip title={"Click on the link to view/edit Team"} arrow>
          <span>
            <a
              href="/"
              onClick={(e) => onEditClick(e, id)}
              className="tab-font-wrap"
              title={value}
            >
              {value}
            </a>
          </span>
        </Tooltip>
      );
    } else {
      switch (column.id) {
        case "pois":
          return getViewMoreUI(value);
        case "country":
          return (
            <span className="tab-font-wrap" title={value}>
              {value}
            </span>
          );
        case "city":
          return (
            <span className="tab-font-wrap" title={value}>
              {value}
            </span>
          );
        case "integration_id":
          return (
            <span className="tab-font-wrap" title={value}>
              {value}
            </span>
          );
        default:
          return "";
      }
    }
  };

  const getViewMoreUI = (value) => {
    let html;
    if (value && value.length) {
      if (value.length > 1) {
        html = (
          <span className="tab-font-wrap">
            {value[0].name}
            <span style={{ color: "blue", cursor: "pointer" }}>
              <Tooltip
                title={value.slice(1).map((tip) => {
                  return <p>{tip.name}</p>;
                })}
                arrow
              >
                <span>+{value.length - 1}</span>
              </Tooltip>
            </span>
          </span>
        );
      } else if (value.length === 1) {
        html = <span>{value[0].name}</span>;
      }
    }

    return html;
  };

  const onEditClick = (e, id) => {
    props.onEditClick(id);
    e.preventDefault();
  };

  const handleChangePage = (event, newPage) => {
    props.onPageChange(newPage);
  };

  useEffect(() => {
    setDataList(props.dataList);
    setTotalItemCount(props.totalItemCount);
    selectedFilters.current = props.selectedFilters;
    copyDataList.current = props.dataList;
  }, [props]);

  return (
    <div className="col-sm-12 wrapper">
      <TableContainer sx={{ maxHeight: "63vh", minHeight: "63vh" }}>
        <Table stickyHeader aria-label="sticky table" className="list-table">
          <TableHead>
            <TableRow>
              <TableCell className="sticky-checkbox-header">
                <Checkbox
                  className="mt-5"
                  checked={selectAll}
                  onChange={onChangeSelectAll}
                  indeterminate={isIndeterminate}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                />
              </TableCell>
              {columns.map((column) => (
                <React.Fragment key={column.id}>
                  {column.filterType === "search" ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={
                        column.id === "name" ? "sticky-header-name" : ""
                      }
                    >
                      <label className="pb-1 d-block">{column.label}</label>
                      <TextField
                        id="search"
                        label="Search"
                        variant="outlined"
                        size="small"
                        onChange={(ev) => search(ev.target.value, column.id)}
                        value={selectedFilters.current[column.id]}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      <label className="pb-1 d-block">{column.label}</label>
                      <Autocomplete
                        size="small"
                        multiple
                        sx={{ width: "100%" }}
                        limitTags={1}
                        id="multiple-limit-tags"
                        // filterSelectedOptions={true}
                        options={props.dropdownFilters[column.id]}
                        getOptionLabel={(option) => option.name || ""}
                        value={props.selectedFilters[column.id]}
                        onChange={(ev, val) => {
                          props.onFiltersChange(val, column.id);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.id}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            sx={{ fontSize: 14 }}
                            {...params}
                            // label={column.label}
                            placeholder="Select"
                          />
                        )}
                      />
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          {props.isLoading === "true" ? (
            <TableBody>
              <tr>
                <td colSpan="4">
                  <CircularProgress
                    style={{
                      position: "absolute",
                      margin: "auto",
                      top: "50%",
                      left: "50%",
                    }}
                    children
                  />
                </td>
              </tr>
            </TableBody>
          ) : (
            <>
              {props.totalItemCount ? (
                <TableBody>
                  {dataList.map((row, rowIndex) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowIndex}
                      >
                        <TableCell key={rowIndex} className="sticky">
                          <Checkbox
                            checked={row.checked}
                            onChange={(e) => onListSelect(e, row)}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 20 },
                            }}
                          />
                        </TableCell>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <React.Fragment key={column.id}>
                              <TableCell
                                key={column.id}
                                align={column.align}
                                className={
                                  column.id === "name" ? "sticky-name" : ""
                                }
                              >
                                <>{getUiElement(column, value, row["id"])}</>
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <div
                  className="select-account"
                  style={{
                    display: "block",
                    left: "50%",
                  }}
                >
                  <p>No data in the selected filter</p>
                </div>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <div className="pagination-container">
        <div className="selected-count-wrapper">
          Total {totalItemCount} | Selected {selectedListCount}
        </div>
        <div className="pagination-wrapper">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalItemCount}
            rowsPerPage={rowsPerPage}
            page={props.currentPage}
            showFirstButton
            showLastButton
            onPageChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
