import './App.css';
import TeamsList from './pages/TeamsList/TeamsList';

function App() {
  return (
    <TeamsList></TeamsList>
  );
}

export default App;
