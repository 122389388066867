import { gql } from "@apollo/client";

export const GET_POIS = gql`
  query PoiQuery($userId: Int!, $pageNo: Int!, $name: String) {
    getUserPois(filters: { user_id: $userId, page_no: $pageNo, name: $name }) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_ASSIGNED_POIS = gql`
  query AssignedPoiQuery(
    $userId: Int!
    $pageNo: Int!
    $name: String
    $assignType: String
    $teamId: Int!
  ) {
    getTeamAssignedPois(
      filters: {
        user_id: $userId
        page_no: $pageNo
        name: $name
        assign_type: $assignType
        team_id: $teamId
      }
    ) {
      total
      data {
        id
        name
        assign_type
        is_checked
      }
    }
  }
`;
