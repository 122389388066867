import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SettingsMenu from "../SettingsMenu/SettingsMenu";
import { TabContext, TabList } from "@mui/lab";
import PropTypes from "prop-types";
import TeamsDetails from "../../pages/TeamsDetails/TeamsDetails";
import AssignedPois from "../../pages/AssignedPois/AssignedPois";
import AssignedDrivers from "../../pages/AssignedDrivers/AssignedDrivers";
import Configs from "../../pages/Configs/Configs";
import ConfirmationDialog from "../ConfirmDialog/ConfirmationDialog";
import AlertDialog from "../AlertDialog/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import { reloadTeamList } from "../../reducers/teamsLists";
import TeamsService from "../../services/TeamsService";
import { useMutation } from "@apollo/client";
import {
  ACTIVATE_TEAM,
  CREATE_TEAM,
  DEACTIVATE_TEAM,
  DELETE_TEAM,
  UPDATE_TEAM,
} from "../../graphql/mutations/TeamsListMutation";
import { dropdownOptions, formDetailsTab } from "../../reducers/detailTab";
import { detailsFormErrorValidations } from "../../reducers/detailsFormValidations";
import { poiAssign } from "../../reducers/assignedPoi";
import { assignDriver } from "../../reducers/assignedDrivers";
import { configDefaultValues, configsTab } from "../../reducers/configs";
import CommonService from "../../services/CommonService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabModal(props) {
  const teamsService = new TeamsService();
  const commonService = new CommonService();

  const dispatch = useDispatch();

  const teamListSelector = useSelector((state) => state.teamsLists.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);

  const [createTeamMutation] = useMutation(CREATE_TEAM);
  const [updateTeamMutation] = useMutation(UPDATE_TEAM);
  const [activateTeamMutation] = useMutation(ACTIVATE_TEAM);
  const [deactivateTeamMutation] = useMutation(DEACTIVATE_TEAM);
  const [deleteTeamMutation] = useMutation(DELETE_TEAM);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [scroll] = React.useState("paper");
  const [isLoading, setIsLoading] = React.useState("false");
  const [confirmProps, setConfirmProps] = React.useState({
    title: "",
    id: "",
    description: "",
    display: false,
  });
  const [alertProps, setAlertProps] = React.useState({
    title: "",
    id: "",
    description: "",
    display: false,
  });
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    type: "",
    message: "",
  });
  const hideOptions = React.useRef({
    showActivate: false,
    showDeactivate: false,
  });

  const closeDialog = () => {};

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (ev) => {
    setValue(0);
    setTabIndex(0);
    dispatch(dropdownOptions({}));
    if (
      teamListSelector.selectedAccount &&
      teamListSelector.selectedAccount.id
    ) {
      dispatch(
        formDetailsTab({
          client_id: teamListSelector.selectedAccount,
          latitude: 25.2048,
          longitude: 55.2708,
        })
      );
    } else {
      dispatch(
        formDetailsTab({
          latitude: 25.2048,
          longitude: 55.2708,
        })
      );
    }
    dispatch(detailsFormErrorValidations({}));
    dispatch(poiAssign([]));
    dispatch(assignDriver([]));
    dispatch(configsTab(configDefaultValues));
    setIsDialogOpen(false);
  };

  const handleClose = () => {
    setConfirmProps({
      title: "Cancel",
      id: "cancel",
      description: "All the changes will be discarded. Do you want to proceed?",
      display: true,
    });
  };

  const handleChange = (event, newValue) => {
    switch (event.target.innerText) {
      case "Details":
        setValue(0);
        break;
      case "Assigned POIs":
        setValue(1);
        break;
      case "Assigned Drivers":
        setValue(2);
        break;
      case "Configs":
        setValue(3);
        break;

      default:
        break;
    }
    setTabIndex(newValue);
  };

  const getComponent = (idx) => {
    switch (idx) {
      case 0:
        return <TeamsDetails />;
      case 1:
        return <AssignedPois />;
      case 2:
        return <AssignedDrivers />;
      case 3:
        return <Configs />;
      default:
        return null;
    }
  };

  const onSubmitClick = () => {
    const checkForValidation = teamsService.formValidation();
    if (checkForValidation.isValid) {
      setConfirmProps({
        title: "Save",
        id: "save",
        description: "All the changes will be saved. Do you want to proceed?",
        display: true,
      });
    } else {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        type: "error",
        message: checkForValidation.message,
      });
    }
  };

  const onConfirmClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
    switch (ev.id) {
      case "cancel":
        handleCloseDialog();
        break;
      case "save":
        onSubmit();
        break;
      case "activate":
        activateTeam();
        break;
      case "deactivate":
        deactivateTeam();
        break;
      case "delete":
        deleteTeam();
        break;
      default:
        return;
    }
  };

  const activateTeam = async () => {
    setIsLoading("true");
    activateTeamMutation({
      variables: { ids: detailsTabSelector.team_id },
      onCompleted(successResponse) {
        if (successResponse.activateTeams.success == true) {
          setAlertProps({
            title: "Activated",
            id: "activateSuccess",
            description: "The Team(s) have been activated successfully.",
            display: true,
          });
          hideOptions.current = { showActivate: false, showDeactivate: true };
          const tempFields = commonService.getDeepCopy(detailsTabSelector);
          tempFields["is_active"] = 0;
          dispatch(formDetailsTab(tempFields));
        } else {
          setAlertProps({
            title: "Error",
            id: "activateError",
            description: successResponse.activateTeams.data,
            display: true,
          });
        }

        setIsLoading("false");
      },
      onError(errorResponse) {
        const errMessage = errorResponse.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "activateError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const deactivateTeam = async () => {
    setIsLoading("true");
    deactivateTeamMutation({
      variables: { ids: detailsTabSelector.team_id },
      onCompleted(successResponse) {
        if (successResponse.deactivateTeams.success == true) {
          setAlertProps({
            title: "Deactivate",
            id: "deactivateSuccess",
            description: "The Team(s) have been deactivated successfully.",
            display: true,
          });
          hideOptions.current = { showActivate: true, showDeactivate: false };
          const tempFields = commonService.getDeepCopy(detailsTabSelector);
          tempFields["is_active"] = 0;
          dispatch(formDetailsTab(tempFields));
        } else {
          setAlertProps({
            title: "Error",
            id: "deactivateError",
            description: successResponse.deactivateTeams.data,
            display: true,
          });
        }
        setIsLoading("false");
      },
      onError(errorResponse) {
        const errMessage = errorResponse.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "deactivateError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const deleteTeam = async () => {
    setIsLoading("true");
    deleteTeamMutation({
      variables: { ids: detailsTabSelector.team_id },
      onCompleted(successResponse) {
        if (successResponse.deleteTeams.success == true) {
          setAlertProps({
            title: "Delete",
            id: "deleteSuccess",
            description: "The Team(s) have been deleted successfully.",
            display: true,
          });
          dispatch(reloadTeamList({ isReload: true }));
        } else {
          setAlertProps({
            title: "Error",
            id: "deleteError",
            description: successResponse.deleteTeams.data,
            display: true,
          });
        }
        setIsLoading("false");
      },
      onError(errorResponse) {
        const errMessage = errorResponse.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "deleteError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const onSubmit = () => {
    setAlertProps({ title: "", id: "", description: "", display: false });
    setIsLoading("true");
    if (teamListSelector.viewType == "add") {
      createTeam();
    } else {
      updateTeam();
    }
  };

  const createTeam = () => {
    const prepareCreateTeamData = teamsService.prepareCreateUpdateTeamData();
    createTeamMutation({
      variables: prepareCreateTeamData,
      onCompleted(successResp) {
        if (successResp.createTeam.success == true) {
          dispatch(reloadTeamList({ isReload: true }));
          setAlertProps({
            title: "Success!",
            id: "saveSuccess",
            description: "Team has been created successfully",
            display: true,
          });
          setIsLoading("false");
        } else {
          setAlertProps({
            title: "Error",
            id: "saveError",
            description: successResp.createTeam.data,
            display: true,
          });
          setIsLoading("false");
        }
      },
      onError(errorResp) {
        const errMessage = errorResp.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "saveError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const updateTeam = () => {
    const prepareUpdateTeamData = teamsService.prepareCreateUpdateTeamData();
    updateTeamMutation({
      variables: prepareUpdateTeamData,
      onCompleted(successResp) {
        if (successResp.updateTeam) {
          dispatch(reloadTeamList({ isReload: true }));
          setAlertProps({
            title: "Success!",
            id: "updateSuccess",
            description: "Team has been updated successfully",
            display: true,
          });
          setIsLoading("false");
        } else {
          setAlertProps({
            title: "Error",
            id: "updateError",
            description: "Something went wrong! Please try again",
            display: true,
          });
          setIsLoading("false");
        }
      },
      onError(errorResp) {
        const errMessage = errorResp.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "updateError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      type: "",
      message: "",
    });
  };

  const onAlertClose = () => {
    if (alertProps.id === "saveSuccess" || alertProps.id === "deleteSuccess") {
      handleCloseDialog();
    }
    setAlertProps({ title: "", id: "", description: "", display: false });
  };

  const onCancelClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
  };

  const onSelectSettingsOption = (inOption) => {
    if (inOption !== "close") {
      setConfirmProps(
        teamsService.selectedSettingsOptionsForTeamList(inOption)
      );
    }
  };

  useEffect(() => {
    props.tabModalChild.current = handleOpenDialog;
  }, []);

  useEffect(() => {
    if (teamListSelector.viewType == "edit") {
      if (detailsTabSelector.is_active === 1) {
        hideOptions.current = { showActivate: false, showDeactivate: true };
      } else {
        hideOptions.current = { showActivate: true, showDeactivate: false };
      }
    } else {
      hideOptions.current = { showActivate: false, showDeactivate: false };
    }
  }, [detailsTabSelector.is_active]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: snackBarState.vertical,
          horizontal: snackBarState.horizontal,
        }}
        autoHideDuration={6000}
        open={snackBarState.open}
        key={snackBarState.vertical + snackBarState.horizontal}
        onClose={closeSnackBar}
      >
        <Alert
          onClose={closeSnackBar}
          severity={snackBarState.type}
          sx={{ width: "100%" }}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        confirmProps={confirmProps}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />
      <AlertDialog alertProps={alertProps} onAlertClose={onAlertClose} />
      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={closeDialog}
        sx={{
          position: "absolute",
          maxWidth: "60%",
          left: "40%",
        }}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component={"div"}
              sx={{ fontSize: "1rem !important", fontWeight: "500" }}
            >
              {props.tabTitle}
            </Typography>
            {teamListSelector.viewType == "edit" && (
              <SettingsMenu
                pageType={"dashboard"}
                hideOptions={hideOptions.current}
                onSelectSettingsOption={(ev) => onSelectSettingsOption(ev)}
              ></SettingsMenu>
            )}
          </Box>

          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="POI Tabs"
            >
              {props.tabsList.map((el, idx) =>
                el.isDisabled ? (
                  <Tooltip
                    key={idx}
                    arrow
                    title={
                      el.isDisabled &&
                      "Please select Account, Country, City to enable this"
                    }
                  >
                    <div>
                      <Tab
                        style={{ textTransform: "capitalize" }}
                        label={el.name}
                        disabled={el.isDisabled}
                        {...a11yProps(el.idx)}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tab
                    key={idx}
                    style={{ textTransform: "capitalize" }}
                    label={el.name}
                    {...a11yProps(0)}
                  />
                )
              )}
            </Tabs>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
          tabIndex={-1}
        >
          {isLoading === "true" ? (
            <CircularProgress
              style={{
                position: "absolute",
                margin: "auto",
                top: "50%",
                left: "50%",
              }}
            />
          ) : (
            <>
              {props.tabsList.map((el) => (
                <TabPanel value={value} index={el.idx} key={el.idx}>
                  {getComponent(value)}
                </TabPanel>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          {props.user.permissions?.teams_viewTeams &&
            ((props.user.permissions?.teams_editTeam &&
              props.viewType === "edit") ||
              (props.viewType === "add" &&
                props.user.permissions.teams_createTeam)) && (
              <Button variant="contained" onClick={onSubmitClick}>
                Save
              </Button>
            )}
        </DialogActions>
      </Dialog>
    </>
  );
}
