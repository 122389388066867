import { createSlice } from "@reduxjs/toolkit";

export const assignedPoiSlice = createSlice({
  name: "poiAssign",
  initialState: { value: [] },
  reducers: {
    poiAssign: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { poiAssign } = assignedPoiSlice.actions;

export default assignedPoiSlice.reducer;
