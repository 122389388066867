import { createSlice } from "@reduxjs/toolkit";

export const teamsListSlice = createSlice({
  name: "teamsLists",
  initialState: {
    value: {
      selectedAccount: {},
      viewType: "",
      selectedTeam: "",
      accountsList: [],
    },
    reloadState: { isReload: false },
  },
  reducers: {
    teamsLists: (state, action) => {
      state.value = action.payload;
    },
    reloadTeamList: (state, action) => {
      state.reloadState = action.payload;
    },
  },
});

export const { teamsLists, reloadTeamList } = teamsListSlice.actions;

export default teamsListSlice.reducer;
