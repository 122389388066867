import { createSlice } from "@reduxjs/toolkit";

const requiredFields = {
  name: "",
  country_id: "",
  client_id: "",
  city_id: "",
};

export const detailsFormValidationSlice = createSlice({
  name: "detailsFormErrorValidations",
  initialState: { value: requiredFields },
  reducers: {
    detailsFormErrorValidations: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { detailsFormErrorValidations } =
  detailsFormValidationSlice.actions;

export default detailsFormValidationSlice.reducer;
