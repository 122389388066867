import CommonService from "./CommonService";
import { useApolloClient } from "@apollo/client";
import { GET_ASSIGNED_POIS, GET_POIS } from "../graphql/queries/PoiQuery";

export class PoisService {
  commonService = new CommonService();

  client = useApolloClient();

  getPoisList = async (inUserId, inPageNo, inName) => {
    const tempGetAssignedPois = await this.client.query({
      query: GET_POIS,
      variables: {
        userId: inUserId,
        pageNo: inPageNo,
        name: inName,
      },
      fetchPolicy: "network-only",
    });

    const tempPoisList = this.commonService.getDeepCopy(
      tempGetAssignedPois.data.getUserPois.data
    );
    tempPoisList.forEach((val) => {
      val["checked"] = val.is_checked;
      if (!val.name) {
        val["name"] = "";
      }
    });

    return {
      total: tempGetAssignedPois.data.getUserPois.total,
      poisList: tempPoisList,
    };
  };

  getAssignedPoisList = async (inUserId, inPageNo, inName, inAssignType,inTeamId) => {
    const tempGetAssignedPois = await this.client.query({
      query: GET_ASSIGNED_POIS,
      variables: {
        userId: inUserId,
        pageNo: inPageNo,
        name: inName,
        assignType: inAssignType,
        teamId: inTeamId,
      },
      fetchPolicy: "network-only",
    });

    const tempPoisList = this.commonService.getDeepCopy(
      tempGetAssignedPois.data.getTeamAssignedPois.data
    );
    tempPoisList.forEach((val) => {
      val["checked"] = val.is_checked;
      if (!val.name) {
        val["name"] = "";
      }
      val["team_type"] = val["assign_type"];
      val["team_type_text"] = this.getTeamTypeText(val["assign_type"]);
    });

    return {
      total: tempGetAssignedPois.data.getTeamAssignedPois.total,
      poisList: tempPoisList,
    };
  };

  getTeamTypeText = (type) => {
    let teamType;
    switch (type) {
      case "NONE":
        teamType = "";
        break;
      case "DOD":
        teamType = "DOD/Force on-demand";
        break;
      case "DEDICATED":
        teamType = "Dedicated";
        break;
      case "PREASSIGN":
        teamType = "Pre-assign";
        break;
      case "POOLING":
        teamType = "On-demand/Pooling";
        break;
      case "SACN":
        teamType = "Scan to assign";
        break;
      default:
        teamType = "";
    }
    return teamType;
  };
}
