import { gql } from "@apollo/client";

export const ACTIVATE_TEAM = gql`
  mutation activateTeams($ids: [Int]!) {
    activateTeams(ids: $ids) {
      success
    }
  }
`;

export const DEACTIVATE_TEAM = gql`
  mutation deactivateTeams($ids: [Int]!) {
    deactivateTeams(ids: $ids) {
      success
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeams($ids: [Int]!) {
    deleteTeams(ids: $ids) {
      success
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation createTeam(
    $name: String!
    $short_name: String
    $country_id: Int!
    $client_id: Int!
    $city_id: Int!
    $drivers: String!
    $edge_points: String!
    $pois: [TeamPoiInput]
    $user_id: Int!
    $alerts: [TeamAlertInput]
    $cashier_start_date: String
    $is_cashier_team_on: String
    $is_double_auth: String
    $CCOD_parnter_select: String
    $is_double_petty_cash: String
    $is_shift_message: String
    $payment_method_through_app: String
  ) {
    createTeam(
      input: {
        name: $name
        short_name: $short_name
        country_id: $country_id
        client_id: $client_id
        city_id: $city_id
        drivers: $drivers
        edge_points: $edge_points
        pois: $pois
        user_id: $user_id
        settings: {
          alerts: $alerts
          configs: {
            cashier_start_date: $cashier_start_date
            is_cashier_team_on: $is_cashier_team_on
            CCOD_parnter_select: $CCOD_parnter_select
            is_double_auth: $is_double_auth
            is_double_petty_cash: $is_double_petty_cash
            is_shift_message: $is_shift_message
            payment_method_through_app: $payment_method_through_app
          }
        }
      }
    ) {
      success
      data
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam(
    $teamId: Int!
    $name: String!
    $short_name: String
    $country_id: Int!
    $client_id: Int!
    $city_id: Int!
    $drivers: String!
    $edge_points: String!
    $pois: [TeamPoiInput]
    $user_id: Int!
    $alerts: [TeamAlertInput]
    $cashier_start_date: String
    $is_cashier_team_on: String
    $is_double_auth: String
    $CCOD_parnter_select: String
    $is_double_petty_cash: String
    $is_shift_message: String
    $payment_method_through_app: String
  ) {
    updateTeam(
      input: {
        team_id: $teamId
        name: $name
        short_name: $short_name
        country_id: $country_id
        client_id: $client_id
        city_id: $city_id
        drivers: $drivers
        edge_points: $edge_points
        pois: $pois
        user_id: $user_id
        settings: {
          alerts: $alerts
          configs: {
            cashier_start_date: $cashier_start_date
            is_cashier_team_on: $is_cashier_team_on
            CCOD_parnter_select: $CCOD_parnter_select
            is_double_auth: $is_double_auth
            is_double_petty_cash: $is_double_petty_cash
            is_shift_message: $is_shift_message
            payment_method_through_app: $payment_method_through_app
          }
        }
      }
    ) {
      id
      name
      is_active
      client_id
      country {
        id
        name
      }
      city {
        id
        name
      }
      short_name
      settings {
        configs {
          CCOD_parnter_select
          cashier_start_date
          is_cashier_team_on
          is_double_auth
          is_double_petty_cash
          is_shift_message
          payment_method_through_app
        }
        alerts {
          alert_type
          alert_value
          id
          is_alert_on
          team_id
        }
      }
    }
  }
`;
