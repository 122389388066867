import { createSlice } from "@reduxjs/toolkit";

export const assignedDriverSlice = createSlice({
  name: "assignDriver",
  initialState: { value: [] },
  reducers: {
    assignDriver: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { assignDriver } = assignedDriverSlice.actions;

export default assignedDriverSlice.reducer;
