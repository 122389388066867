import { gql } from "@apollo/client";

export const GET_DRIVERS = gql`
  query DriverListListQuery($userId: Int!, $pageNo: Int!, $name: String) {
    getUserDrivers(
      filters: { page_no: $pageNo, user_id: $userId, name: $name }
    ) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_ASSIGNED_DRIVERS = gql`
  query AssignedDriversQuery(
    $userId: Int!
    $pageNo: Int!
    $name: String
    $teamId: Int!
  ) {
    getTeamAssignedDrivers(
      filters: { user_id: $userId, page_no: $pageNo, name: $name, team_id: $teamId }
    ) {
      total
      data {
        id
        name
        is_checked
      }
    }
  }
`;
