import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import BasicConfigs from "./BasicConfigs/BasicConfigs";
import AdvancedConfigs from "./AdvancedConfigs/AdvancedConfigs";

export default function Configs() {
  const [tabValue, setTabValue] = React.useState("1");
  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={onTabChange}>
          <Tab label="Basic" value="1" />
          <Tab label="Advanced" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <BasicConfigs></BasicConfigs>
      </TabPanel>
      <TabPanel value="2" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <AdvancedConfigs></AdvancedConfigs>
      </TabPanel>
    </TabContext>
  );
}
