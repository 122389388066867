import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonService from "../../../services/CommonService";
import { useDispatch, useSelector } from "react-redux";
import { configsTab } from "../../../reducers/configs";
import dayjs from "dayjs";

export default function BasicConfigs() {
  const commonService = new CommonService();

  const dispatch = useDispatch();

  const configsTabSelector = useSelector((state) => state.configsTab.value);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onChangeAlertLimit = (inValue, inId, alertType, fieldType) => {
    let tempList = commonService.getDeepCopy(configsTabSelector);
    let value = inValue;
    if (fieldType === "is_on") {
      value = inValue ? "1" : "0";
    }
    tempList[alertType].forEach((element) => {
      if (element.id === inId) {
        element[fieldType] = value;
      }
    });
    dispatch(configsTab(tempList));
  };

  const onAddAlertLimit = (inLastId, inField) => {
    let tempList = commonService.getDeepCopy(configsTabSelector);
    tempList[inField].push({
      id: inLastId + 1,
      is_on: 1,
      value: 0,
    });
    dispatch(configsTab(tempList));
  };

  const onStartDateSwitchChange = (inCheckVal) => {
    let tempList = commonService.getDeepCopy(configsTabSelector);
    tempList["is_cashier_team_on"] = inCheckVal ? "true" : "false";
    dispatch(configsTab(tempList));
  };

  const onChangeStartDate = (inStartDate) => {
    const startDate = dayjs(inStartDate.$d).format("YYYY-MM-DD");
    let tempList = commonService.getDeepCopy(configsTabSelector);
    tempList["cashier_start_date"] = startDate;
    dispatch(configsTab(tempList));
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        size="small"
        margin="dense"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography>Cash Limit Alerts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {configsTabSelector.cashLimitAlertFields.map((field, idx) => (
              <React.Fragment key={"cashLimit-" + field.id}>
                <Grid item xs={6}>
                  <Typography>
                    Cash Limit for Warning Alert (0 = Disabled){" "}
                    <Tooltip title="The amount is the cash in hand the driver is holding">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Switch
                        inputProps={{ "aria-label": "controlled" }}
                        checked={field.is_on == "1"}
                        onChange={(ev) =>
                          onChangeAlertLimit(
                            ev.target.checked,
                            field.id,
                            "cashLimitAlertFields",
                            "is_on"
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        type="number"
                        label="Enter"
                        InputProps={{
                          inputProps: {
                            max: 100,
                            min: 0,
                          },
                        }}
                        value={field.value}
                        disabled={field.is_on == "0"}
                        onChange={(ev) =>
                          onChangeAlertLimit(
                            ev.target.value,
                            field.id,
                            "cashLimitAlertFields",
                            "value"
                          )
                        }
                      />
                    </Grid>
                    {configsTabSelector.cashLimitAlertFields.length ===
                      idx + 1 && (
                      <Grid item xs={2}>
                        <Tooltip title="Add Warning Alert">
                          <IconButton
                            sx={{
                              float: "right",
                            }}
                            onClick={() =>
                              onAddAlertLimit(field.id, "cashLimitAlertFields")
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        size="small"
        margin="dense"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography>Time Based Alert</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {configsTabSelector.timeLimitAlertFields.map((field, idx) => (
              <React.Fragment key={"timeLimit-" + field.id}>
                <Grid item xs={6}>
                  <Typography>
                    Time Limit for Warning Alert (0 = Disabled){" "}
                    <Tooltip title="The time is calculated since the driver started his shift from the last cashout">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Switch
                        inputProps={{ "aria-label": "controlled" }}
                        checked={field.is_on == "1"}
                        onChange={(ev) =>
                          onChangeAlertLimit(
                            ev.target.checked,
                            field.id,
                            "timeLimitAlertFields",
                            "is_on"
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        type="number"
                        label="Enter"
                        InputProps={{
                          inputProps: {
                            max: 100,
                            min: 0,
                          },
                          endAdornment: (
                            <InputAdornment position="end"> hrs</InputAdornment>
                          ),
                        }}
                        value={field.value}
                        disabled={field.is_on == "0"}
                        onChange={(ev) =>
                          onChangeAlertLimit(
                            ev.target.value,
                            field.id,
                            "timeLimitAlertFields",
                            "value"
                          )
                        }
                      />
                    </Grid>
                    {configsTabSelector.timeLimitAlertFields.length ===
                      idx + 1 && (
                      <Grid item xs={2}>
                        <Tooltip title="Add Warning Alert">
                          <IconButton
                            sx={{
                              float: "right",
                            }}
                            onClick={() =>
                              onAddAlertLimit(field.id, "timeLimitAlertFields")
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        size="small"
        margin="dense"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography>Cashier Module</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                Cashier Start Date{" "}
                <Tooltip title="Cannot select a date earlier than the date set at Account Level">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    checked={configsTabSelector["is_cashier_team_on"] === "true"}
                    onChange={(ev) =>
                      onStartDateSwitchChange(ev.target.checked)
                    }
                  />
                </Grid>
                {configsTabSelector.is_cashier_team_on === "true" && (
                  <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{
                          textField: { size: "small", error: false },
                        }}
                        sx={{ width: "80%" }}
                        onChange={onChangeStartDate}
                        value={dayjs(configsTabSelector.cashier_start_date)}
                        format="YYYY-MM-DD"
                      />
                    </LocalizationProvider>
                    <Tooltip title="If no date is selected, by default the date set at account level will be taken upon submitting">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
