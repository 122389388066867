import { useDispatch, useSelector } from "react-redux";
import CommonService from "./CommonService";
import { formDetailsTab } from "../reducers/detailTab";
import { detailsFormErrorValidations } from "../reducers/detailsFormValidations";
import { configsTab } from "../reducers/configs";
import { PoisService } from "./PoisService";
import { poiAssign } from "../reducers/assignedPoi";

class TeamsService {
  commonService = new CommonService();
  poiService = new PoisService();

  dispatch = useDispatch();

  teamsListSelector = useSelector((state) => state.teamsLists.value);
  detailsTabSelector = useSelector((state) => state.detailTab.value);
  assignedDriverSelector = useSelector((state) => state.assignedDiver.value);
  assignedPoiSelector = useSelector((state) => state.assignedPoi.value);
  userSelector = useSelector((state) => state.userInfo.value);
  configsTabSelector = useSelector((state) => state.configsTab.value);
  errorValidationSelector = useSelector(
    (state) => state.detailsFormValidations.value
  );

  columns = [
    {
      id: "name",
      label: "Team",
      minWidth: 400,
      filterType: "search",
    },
    {
      id: "country",
      label: "Team Country",
      minWidth: 350,
      filterType: "selectWithSearch",
      zIndex: 11,
    },
    {
      id: "city",
      label: "Team City",
      minWidth: 300,
      filterType: "selectWithSearch",
      zIndex: 10,
    },
    {
      id: "pois",
      label: "Team Assigned POIs",
      minWidth: 300,
      filterType: "selectWithSearch",
      zIndex: 10,
    },
    {
      id: "integration_id",
      label: "Team Integration ID",
      minWidth: 300,
      filterType: "search",
    },
  ];

  tabsList = [
    { idx: 0, name: "Details", isDisabled: false },
    { idx: 1, name: "Assigned POIs", isDisabled: false },
    { idx: 2, name: "Assigned Drivers", isDisabled: false },
    { idx: 3, name: "Configs", isDisabled: false },
  ];

  prepareTeamsList = (copyTeamsList, checkedLists) => {
    const tempTeamsList = this.commonService.getDeepCopy(copyTeamsList);
    tempTeamsList.forEach((val) => {
      val["checked"] = this.checkForSelectedTeam(checkedLists, val.id);
      if (!val.integration_id) {
        val["integration_id"] = "";
      }
      if (!val.name) {
        val["name"] = "";
      }
      if (val.city) {
        val["city"] = val.city.name;
      } else {
        val["city"] = "";
      }
      if (val.country) {
        val["country"] = val.country.name;
      } else {
        val["country"] = "";
      }
    });

    return tempTeamsList;
  };

  checkForSelectedTeam = (checkedLists, inId) => {
    let isExist = false;
    checkedLists.some((el) => {
      isExist = el.id === inId;
    });
    return isExist;
  };

  prepareVariablesToGetTeams = (
    selectedAccount,
    currentPage,
    selectedFilters
  ) => {
    return {
      client_id: selectedAccount.current.id,
      page_no: currentPage.current + 1,
      name: selectedFilters.current.name ? selectedFilters.current.name : "",
      country: selectedFilters.current.country
        ? selectedFilters.current.country.map((el) => el.id).join()
        : "",
      city: selectedFilters.current.city
        ? selectedFilters.current.city.map((el) => el.id).join()
        : "",
      pois: selectedFilters.current.pois
        ? selectedFilters.current.pois.map((el) => el.id).join()
        : "",
      integrationId: selectedFilters.current.integration_id
        ? selectedFilters.current.integration_id.toString()
        : "",
    };
  };

  selectedSettingsOptionsForTeamList = (inOption) => {
    let tempData = { title: "", description: "", display: false };
    switch (inOption) {
      case "activate":
        tempData = {
          title: "Activate",
          id: "activate",
          description:
            "Selected Team(s) will be activated. Do you want to proceed?",
          display: true,
        };
        break;
      case "deactivate":
        tempData = {
          title: "Deactivate",
          id: "deactivate",
          description:
            "Selected Team(s) will be deactivated. Do you want to proceed?",
          display: true,
        };
        break;
      case "delete":
        tempData = {
          title: "Delete",
          id: "delete",
          description:
            "Selected Team(s) will be deleted. Do you want to proceed?",
          display: true,
        };
        break;
      default:
        tempData = {
          title: "Activate",
          id: "activate",
          description:
            "Selected Team(s) will be activated. Do you want to proceed?",
          display: true,
        };
    }

    return tempData;
  };

  initializeGetTeam = (inGetTeamData) => {
    const tempData = this.commonService.getDeepCopy(
      inGetTeamData.data.getTeamByID
    );
    tempData["client_id"] = this.teamsListSelector.selectedAccount;
    tempData["country_id"] = inGetTeamData.data.getTeamByID.country;
    tempData["city_id"] = inGetTeamData.data.getTeamByID.city;
    tempData["team_id"] = inGetTeamData.data.getTeamByID.id;
    tempData["latitude"] = 25.2048;
    tempData["longitude"] = 55.2708;
    if (inGetTeamData.data.getTeamByID.edge_points) {
      let tempPoints = inGetTeamData.data.getTeamByID.edge_points;
      tempPoints += ",";
      tempPoints = tempPoints.replaceAll("(", "").split("),");
      tempPoints.pop();
      const tempArr = [];
      tempPoints.forEach((el) => {
        const tempEl = el.split(",");
        tempArr.push({ lat: tempEl[0].trim(), lng: tempEl[1].trim() });
      });
      tempData["edge_points"] = tempArr;
    }
    this.dispatch(formDetailsTab(tempData));
    this.getAssignedPoisList(inGetTeamData.data.getTeamByID.id);

    const tempConfigData = this.commonService.getDeepCopy(
      inGetTeamData.data.getTeamByID.settings.configs
    );
    tempConfigData["cashier_start_date"] = inGetTeamData.data.getTeamByID
      .settings.configs.cashier_start_date
      ? inGetTeamData.data.getTeamByID.settings.configs.cashier_start_date
      : "";

    const tempCashLimitAlert = inGetTeamData.data.getTeamByID.settings.alerts
      .filter((el) => el.alert_type === "warning")
      .map((tempAlert) => ({
        id: tempAlert.id,
        is_on: tempAlert.is_alert_on,
        value: tempAlert.alert_value,
      }));
    tempConfigData["cashLimitAlertFields"] =
      tempCashLimitAlert && tempCashLimitAlert.length
        ? tempCashLimitAlert
        : [{ id: 0, is_on: "1", value: 0 }];

    const tempTimeLimitAlert = inGetTeamData.data.getTeamByID.settings.alerts
      .filter((el) => el.alert_type === "time_warning")
      .map((tempAlert) => ({
        id: tempAlert.id,
        is_on: tempAlert.is_alert_on,
        value: tempAlert.alert_value,
      }));
    tempConfigData["timeLimitAlertFields"] =
      tempTimeLimitAlert && tempTimeLimitAlert.length
        ? tempTimeLimitAlert
        : [{ id: 0, is_on: "1", value: 0 }];

    const checkForTimeBlock =
      inGetTeamData.data.getTeamByID.settings.alerts.filter(
        (el) => el.alert_type === "time_block"
      );
    if (checkForTimeBlock && checkForTimeBlock.length) {
      tempConfigData["timeLimitBlockerField"] = {
        is_on: checkForTimeBlock[0].is_alert_on,
        value: checkForTimeBlock[0].alert_value,
      };
    } else {
      tempConfigData["timeLimitBlockerField"] = { is_on: "1", value: 0 };
    }

    const checkForBlock = inGetTeamData.data.getTeamByID.settings.alerts.filter(
      (el) => el.alert_type === "block"
    );
    if (checkForTimeBlock && checkForTimeBlock.length) {
      tempConfigData["cashLimitBlockerField"] = {
        is_on: checkForBlock[0].is_alert_on,
        value: checkForBlock[0].alert_value,
      };
    } else {
      tempConfigData["cashLimitBlockerField"] = { is_on: "1", value: 0 };
    }

    this.dispatch(configsTab(tempConfigData));
  };

  getAssignedPoisList = async (inTeamId) => {
    const tempGetAssignedPois = this.poiService.getAssignedPoisList(
      this.userSelector.id,
      0,
      "",
      "",
      inTeamId
    );

    this.dispatch(poiAssign((await tempGetAssignedPois).poisList));
  };

  prepareCreateUpdateTeamData = () => {
    return {
      teamId:
        this.teamsListSelector.viewType == "edit" &&
        this.detailsTabSelector.team_id,
      name: this.detailsTabSelector.name,
      short_name: this.detailsTabSelector.short_name,
      country_id: this.detailsTabSelector.country_id?.id,
      client_id: this.detailsTabSelector.client_id?.id,
      city_id: this.detailsTabSelector.city_id?.id,
      drivers: this.assignedDriverSelector
        .filter((checkData) => checkData.checked)
        .map((driver) => driver.id)
        .join(),
      edge_points: this.detailsTabSelector.edge_points
        ? this.prepareEdgePoints(this.detailsTabSelector.edge_points)
        : "",
      pois: this.assignedPoiSelector
        .filter((checkData) => checkData.team_type)
        .map((poi) => ({
          poi_id: poi.id,
          team_type: poi.team_type,
        })),
      user_id: this.userSelector.id,
      alerts: this.prepareAlerts(),
      cashier_start_date: this.configsTabSelector.cashier_start_date,
      is_cashier_team_on: this.configsTabSelector.is_cashier_team_on,
      CCOD_parnter_select: this.configsTabSelector.CCOD_parnter_select,
      is_double_auth: this.configsTabSelector.is_double_auth,
      is_shift_message: this.configsTabSelector.is_shift_message,
      payment_method_through_app:
        this.configsTabSelector.payment_method_through_app,
    };
  };

  prepareEdgePoints(inVal) {
    let edgePoints = "";
    inVal.forEach((el, idx) => {
      if (idx === inVal.length - 1) {
        edgePoints += "(" + el.lat + ", " + el.lng + ")";
      } else {
        edgePoints += "(" + el.lat + ", " + el.lng + "),";
      }
    });
    return edgePoints;
  }

  prepareAlerts = () => {
    const tempArr = [];
    const tempCashWarning = this.configsTabSelector.cashLimitAlertFields.map(
      (field) => ({
        type: "warning",
        value: field.value,
        is_on: field.is_on,
      })
    );
    const tempTimeWarning = this.configsTabSelector.timeLimitAlertFields.map(
      (field) => ({
        type: "time_warning",
        value: field.value,
        is_on: field.is_on,
      })
    );
    const tempCashBlock = {
      type: "block",
      value: this.configsTabSelector.cashLimitBlockerField.value,
      is_on: this.configsTabSelector.cashLimitBlockerField.is_on,
    };
    const tempTimeBlock = {
      type: "time_block",
      value: this.configsTabSelector.timeLimitBlockerField.value,
      is_on: this.configsTabSelector.timeLimitBlockerField.is_on,
    };
    tempArr.push(...tempCashWarning);
    tempArr.push(...tempTimeWarning);
    tempArr.push(tempCashBlock);
    tempArr.push(tempTimeBlock);
    return tempArr;
  };

  formValidation = () => {
    const reqFields = ["name", "country_id", "client_id", "city_id"];
    const fieldData = this.commonService.getDeepCopy(this.detailsTabSelector);
    const tempValidation = this.commonService.getDeepCopy(
      this.errorValidationSelector
    );
    let validationState = { isValid: true, message: "" };
    reqFields.forEach((req) => {
      if (!fieldData[req]) {
        validationState.isValid = false;
        validationState.message =
          "Please fill in the mandatory fields from Details Tab";
        tempValidation[req] = "Field is required";
      } else {
        tempValidation[req] = "";
      }
    });
    this.dispatch(detailsFormErrorValidations(tempValidation));

    return validationState;
  };
}

export default TeamsService;
