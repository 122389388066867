import { createSlice } from "@reduxjs/toolkit";

export const configDefaultValues = {
  timeLimitAlertFields: [{ id: 0, is_on: "1", value: 0 }],
  cashLimitAlertFields: [{ id: 0, is_on: "1", value: 0 }],
  timeLimitBlockerField: { is_on: "1", value: 0 },
  cashLimitBlockerField: { is_on: "1", value: 0 },
  cashier_start_date: "",
};

export const configsTabSlice = createSlice({
  name: "configsTab",
  initialState: {
    value: configDefaultValues,
    ccodPertnersList: [],
  },
  reducers: {
    configsTab: (state, action) => {
      state.value = action.payload;
    },
    ccodPernters: (state, action) => {
      state.ccodPertnersList = action.payload;
    },
  },
});

export const { configsTab, ccodPernters } = configsTabSlice.actions;

export default configsTabSlice.reducer;
