import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import CommonService from "../../../services/CommonService";
import { ccodPernters, configsTab } from "../../../reducers/configs";
import { useApolloClient } from "@apollo/client";
import { GET_CCOD_PARTNERS } from "../../../graphql/queries/CommonQuery";

export default function AdvancedConfigs() {
  const commonService = new CommonService();
  const client = useApolloClient();
  const dispatch = useDispatch();

  const configsTabSelector = useSelector((state) => state.configsTab.value);
  const ccodPertnersList = useSelector(
    (state) => state.configsTab.ccodPertnersList
  );

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onChangeBlockerLimit = (inValue, alertType, fieldType) => {
    let tempList = commonService.getDeepCopy(configsTabSelector);
    let value = inValue;
    if (fieldType === "is_on") {
      value = inValue ? "1" : "0";
    }
    tempList[alertType][fieldType] = value;
    dispatch(configsTab(tempList));
  };

  const getCCODPartnersList = async () => {
    const tempGetPartners = await client.query({
      query: GET_CCOD_PARTNERS,
      fetchPolicy: "network-only",
    });

    dispatch(ccodPernters(tempGetPartners.data.getCCODPartners));
  };

  const onOthersFieldChange = (inValue, fieldType) => {
    let tempList = commonService.getDeepCopy(configsTabSelector);
    let value = inValue;
    if (fieldType !== "CCOD_parnter_select") {
      value = inValue ? "true" : "false";
    }
    tempList[fieldType] = value;
    dispatch(configsTab(tempList));
  };

  useEffect(() => {
    if (!ccodPertnersList.length) {
      getCCODPartnersList();
    }
  }, []);

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        size="small"
        margin="dense"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography>Cash Limit Alerts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                Cash Limit for Blocker Alert (0 = Disabled){" "}
                <Tooltip title="The amount is the cash in hand the driver is holding">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    checked={
                      configsTabSelector.cashLimitBlockerField.is_on == "1"
                    }
                    onChange={(ev) =>
                      onChangeBlockerLimit(
                        ev.target.checked,
                        "cashLimitBlockerField",
                        "is_on"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    type="number"
                    label="Enter"
                    InputProps={{
                      inputProps: {
                        max: 100,
                        min: 0,
                      },
                    }}
                    value={configsTabSelector.cashLimitBlockerField.value}
                    disabled={
                      configsTabSelector.cashLimitBlockerField.is_on == "0"
                    }
                    onChange={(ev) =>
                      onChangeBlockerLimit(
                        ev.target.value,
                        "cashLimitBlockerField",
                        "value"
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        size="small"
        margin="dense"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography>Time Based Alert</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                Time Limit for Blocker Alert (0 = Disabled){" "}
                <Tooltip title="The time is calculated since the driver started his shift from the last cashout">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    checked={
                      configsTabSelector.timeLimitBlockerField.is_on == "1"
                    }
                    onChange={(ev) =>
                      onChangeBlockerLimit(
                        ev.target.checked,
                        "timeLimitBlockerField",
                        "is_on"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    type="number"
                    label="Enter"
                    InputProps={{
                      inputProps: {
                        max: 100,
                        min: 0,
                      },
                    }}
                    value={configsTabSelector.timeLimitBlockerField.value}
                    disabled={
                      configsTabSelector.timeLimitBlockerField.is_on == "0"
                    }
                    onChange={(ev) =>
                      onChangeBlockerLimit(
                        ev.target.value,
                        "timeLimitBlockerField",
                        "value"
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        size="small"
        margin="dense"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography>Others</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography>CCOD Payment Partner</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                select
                variant="outlined"
                label="Select"
                sx={{ width: "100%" }}
                value={configsTabSelector["CCOD_parnter_select"]}
                onChange={(ev) =>
                  onOthersFieldChange(ev.target.value, "CCOD_parnter_select")
                }
              >
                <MenuItem value={"0"}>NONE</MenuItem>
                {ccodPertnersList.map((list) => (
                  <MenuItem value={list.id}>{list.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                Do not allow Change Payment method through App
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 0 }}>
              <Switch
                inputProps={{ "aria-label": "controlled" }}
                checked={
                  configsTabSelector["payment_method_through_app"] === "true"
                }
                onChange={(ev) =>
                  onOthersFieldChange(
                    ev.target.checked,
                    "payment_method_through_app"
                  )
                }
              />
            </Grid>
            <Grid item xs={8}>
              <Typography>
                Enable double authentication while cashing out driver
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 0 }}>
              <Switch
                inputProps={{ "aria-label": "controlled" }}
                checked={configsTabSelector["is_double_auth"] === "true"}
                onChange={(ev) =>
                  onOthersFieldChange(ev.target.checked, "is_double_auth")
                }
              />
            </Grid>
            <Grid item xs={8}>
              <Typography>Shift Start Message</Typography>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 0 }}>
              <Switch
                inputProps={{ "aria-label": "controlled" }}
                checked={configsTabSelector["is_shift_message"] === "true"}
                onChange={(ev) =>
                  onOthersFieldChange(ev.target.checked, "is_shift_message")
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
