import { useApolloClient } from "@apollo/client";
import CommonService from "./CommonService";
import {
  GET_ASSIGNED_DRIVERS,
  GET_DRIVERS,
} from "../graphql/queries/DriverQuery";

export class DriversService {
  commonService = new CommonService();

  client = useApolloClient();

  getDriversList = async (inUserId, inPageNo, inName) => {
    const tempGetDrivers = await this.client.query({
      query: GET_DRIVERS,
      variables: {
        userId: inUserId,
        pageNo: inPageNo,
        name: inName,
      },
      fetchPolicy: "network-only",
    });

    const tempDriversList = this.commonService.getDeepCopy(
      tempGetDrivers.data.getUserDrivers.data
    );
    tempDriversList.forEach((val) => {
      val["checked"] = false;
      if (!val.name) {
        val["name"] = "";
      }
    });

    return {
      total: tempGetDrivers.data.getUserDrivers.total,
      driversList: tempDriversList,
    };
  };

  getAssignedDriversList = async (inUserId, inPageNo, inName, inTeamId) => {
    const tempGetAssignedDrivers = await this.client.query({
      query: GET_ASSIGNED_DRIVERS,
      variables: {
        userId: inUserId,
        pageNo: inPageNo,
        name: inName,
        teamId: inTeamId,
      },
      fetchPolicy: "network-only",
    });

    const tempDriversList = this.commonService.getDeepCopy(
      tempGetAssignedDrivers.data.getTeamAssignedDrivers.data
    );
    tempDriversList.forEach((val) => {
      val["checked"] = val.is_checked;
      if (!val.name) {
        val["name"] = "";
      }
    });

    return {
      total: tempGetAssignedDrivers.data.getTeamAssignedDrivers.total,
      driversList: tempDriversList,
    };
  };
}
