import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./reducers";
import Cookies from "js-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));

const session = Cookies.get("symfony");
const uri = process.env.REACT_APP_GRAPHQL_URL;

const client = new ApolloClient({
  fetchOptions: {
    credentials: "include",
  },
  headers: {
    "Authorization": session,
  },
  uri: uri,
  cache: new InMemoryCache(),
});

const appStore = configureStore({
  reducer: rootReducers,
});

root.render(
  <ApolloProvider client={client}>
    <Provider store={appStore}>
        <App />
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
